// Webpack Imports
import * as bootstrap from 'bootstrap';
import '@grubersjoe/slide-menu/dist/slide-menu';
import { createPopper } from '@popperjs/core';

( function () {
	'use strict';

	$('.searchsubmit').on('click', function(e) {
		
		if($('.search-form-container').hasClass('hdn')) {
			e.preventDefault();
			$('.search-form-container').removeClass('hdn');
			
			return false;
		}
	});
	
	$('.hide-search-input-container').on('click', function(e) {
		e.preventDefault();
		$('.search-form-container').addClass('hdn');
		return false;
	});

	$('.icon-menu').on('click', function(){
		$(this).toggleClass('open');
	})

	$(window).on('load resize orientationchange', function() {

		$('#navbar').each(function(){
			var $navbar = $(this);
			const menuElement = document.getElementById('navbar');
			if ($(window).width() > 1200) {

				if(menuElement._slideMenu){
					const menu = menuElement._slideMenu;
					menu.destroy();
				}

			} else{

				if (!$navbar.find('.slide-menu__slider').length) {
					
					const menu = new SlideMenu(menuElement, {
						position: 'left',
						submenuLinkAfter: '<span class="slide-menu-icon sm-left float-end"></span>',
						backLinkBefore: '<span class="slide-menu-icon sm-right float-start me-1"></span>',
					});
				}
			}
		});

		if($('.woocommerce').length){

			// Move title / price for mibile view
			var $title = $('#main .product_title, #main p.price');
			if ($(window).width() < 992) {
				$title.insertBefore('.woocommerce-product-gallery');
			}else{
				$title.insertBefore('#main #product_variations');
				if($('.woocommerce-product-details__short-description').length){
					
					$('.woocommerce-product-details__short-description').insertBefore('#main #product_variations');
				}	
			}

			$('#main .size-guide').insertAfter($('#main label[for=pa_taille] strong'));
			$('#woosq-popup .size-guide').insertAfter($('#woosq-popup label[for=pa_taille] strong'));

			$('.woocommerce-product-gallery__wrapper').each(function(){
				var $carousel = $(this);
				/* Initializes a slick carousel only on mobile screens */
				// slick on mobile
				if ($(window).width() > 992) {
					if ($carousel.hasClass('slick-initialized')) {
						$carousel.slick('unslick');
					}
				} else{
					if (!$carousel.hasClass('slick-initialized')) {
						$carousel.slick({
							slidesToShow: 1,
							slidesToScroll: 1,
							dots: true,
							arrows: true,
							adaptiveHeight: false
						});
					}
				}
			});

		}

		
	});

	// Flickity slider
	if( $('#home_products').length || $('.related.products').length || $('.upsells.products').length){
		$('#home_products .products, .related.products .products, .upsells.products .products').each(function(e){
			var $this = $(this);
			var $groupCells = true;
			if($('#home_products .products').length){
				$groupCells = false;
			}
			$this.flickity({
				cellAlign: 'left',
				groupCells: $groupCells,
				contain:true,
				wrapAround: true,
				autoPlay: true
			});			
		});
	}
	
	// Open flyCart when a preorder product is already added
	$(document.body).on('woofc_cart_loaded', function() {
		if($('.single-product #woofc-area .woocommerce-error').length){
			$('body').addClass('woofc-show');
		}
	});

	// move coupon on checkout page
	if($('.woocommerce-checkout'.length)){
		$('.woocommerce-form-coupon-toggle, .woocommerce-form-coupon').wrapAll('<div id="form-coupon-wrapper">');
		$('#form-coupon-wrapper').insertAfter('.woocommerce-checkout-review-order-table');
		//setTimeout(function(){ }, 3000);

		$( 'form.checkout' ).on( 'change', 'input[name^="payment_method"]', function() {
			$('#payment').append($('#ppc-button'));
		})
	}

	// View switcher
	var $view_switcher = $('.col-view_switcher');
	$view_switcher.on('click', function(e){
		e.preventDefault;
		$(this).find('span').toggleClass('active');
		$('#main .products').toggleClass('roomy');
		if ( Cookies.get('romycookie') != "roomy" ) {
			Cookies.set( "romycookie", "roomy" );
		} else{
			Cookies.set( "romycookie", "");
		}
	})
	$( ".archive ul.products" ).addClass( Cookies.get('romycookie') || "" );
	if ( Cookies.get('romycookie') == "roomy" ){
		$view_switcher.find('span').toggleClass('active');
	}

} )();

function slick_settings(e, nb){
	
	if(e.children().length > nb){
		return { slidesToShow: nb, slidesToScroll: nb };
	}else{
		return "unslick";
	}
}